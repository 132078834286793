.btn {
    border-radius: 5px;
}

.header {
    height: 750px;
    max-height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../common/images/landing_header_desktop.jpeg);
    background-size: auto 750px;
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
}

.header-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
}

.header-content h1 {
    font-size: 7rem;
    font-weight: 700;
    margin: 0;
    margin-bottom: 3.5rem;
}

.header-content h2 {
    margin-bottom: 1.5rem;
}

.header-content h4 {
    font-weight: 400;
    margin: 0;
}

#logo-img {
    width: 200px;
    position: relative;
}

#signup-btn,
#signup-header-btn {
    background-color: #E55812;
    height: 42px;
    padding: 0.2rem 2rem;
    box-sizing: border-box;
}

/* Header nav */
.header-nav {
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    left: 0;
    padding: 0 60px;
    background-color: #fff;
    color: #000;
    z-index: 1000;
    box-shadow: 0 0px 10px #d3d3d3;
}
.header-nav a {
    font-weight: 600;
    color: #000;
}
.header-nav a:hover {
    padding-bottom: 5px;
    border-bottom: 2px solid #fff;
    opacity: 0.9;
}
.header-nav-right {
    align-items: center;
    display: flex;
}
#header-shopping-cart-wrapper {
    position: relative;
}
#header-shopping-cart-img {
    width: 30px;
}
#cart-btn:hover {
    cursor: pointer;
}
#cart-count {
    background-color: #fc975e;
    color: #fff;
    width: 20px;
    height: 20px;
    position: relative;
    display: inline-block;
    font-size: 0.7rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    border-radius: 50%;
    font-weight: 600;
    top: -6px;
    position: absolute;
    right: -10px;
}

@media (max-width: 767px) {
    .header-nav {
        padding: 0 30px;
    }
    .header-content h1 {
        font-size: 4rem;
    }
}

@media (max-width: 992px) {
    #cart-count {
        top: -6px;
    }
}

@media (min-width: 1125px) {
    .header {
        background-size: 100% auto;
    }
}
