#root {
  height: 100vh;
}
.primary-btn {
	background-color: #36f;
	color: #fff;
  font-weight: 600;
}
.primary-btn:hover, .primary-btn:focus {
  background-color: #0e3bc3;
  color: #fff;
}
.transparent-btn {
	background-color: transparent;
	color: #9ea2b5;
	border-color: #9ea2b5;
  font-weight: 600;
}
.transparent-btn:hover, .transparent-btn:focus {
  background-color: #36f;
  color: #fff;
}
.transparent-error-btn {
  background-color: transparent;
	color: #f37474;
	border-color: #f37474;
  font-weight: 600;
}
.transparent-error-btn:hover, .transparent-error-btn:focus {
  background-color: #f37474;
  color: #fff;
}

#dashboard, #fundraising-container, .cms-container {
  display: flex;
  position: relative;
  height: 100%;
}

.pro-sidebar nav {
  background-color: unset;
}

.pro-sidebar nav ul li.active {
  background-color: #e3e3e3;
  color: #000;
}
.pro-sidebar .pro-menu-item.active .pro-icon-wrapper{
  background-color: #999999 !important;
}
.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  background-color: unset;
}
.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item.active > .pro-icon-wrapper {
  background-color: #fff !important;
}
.pro-sidebar .pro-menu .pro-menu-item {
  width: 100%;
}
.pro-sidebar .pro-menu .pro-menu-item.active {
  color: #000;
}

@media only screen and (min-width: 601px) {
  nav, nav .nav-wrapper i, nav a.sidenav-trigger, nav a.sidenav-trigger i {
    height: 80px;
  }
}