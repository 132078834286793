#menu-section {
    padding: 3rem 0;
}
#menu-section p {
    color: #2e2e2e;
}
#title {
    margin-top: 0;
    text-align: center;
    color: #2e2e2e;
    font-size: 2.75rem;
}
#menu-category-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 900px;
    margin-bottom: 50px;
}
#menu-category-wrapper {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}
#menu-category-container .menu-category-box {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 15px 10px 0 10px;
    border: 1px solid transparent;
}
#menu-category-container .menu-category-box:focus {
    background-color: #efefef;
}
#menu-category-container .menu-category-box.active {
    border: 1px solid #b1b1b1;
    background-color: #00998E;
    color: #fff;
}
#menu-category-container .menu-category-box.active p {
    font-weight: 600;
    color: #fff;
}
#menu-category-container .menu-category-box:hover {
    border: 1px solid #b1b1b1;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
    border-radius: 10px;
    cursor: pointer;
}
#menu-category-container .menu-category-box img {
    width: 50px;
}
.menu-category-products.hidden {
    display: none;
}

#menu h3 {
    text-align: center;
    max-width: 300px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.5rem;
    margin-bottom: 3rem;
}

#menu .product-item-wrapper {
    padding: 15px 40px 0 40px;
    text-align: left;
}
#menu .product-name {
    font-size: 1.75rem;
    margin: 0;
    font-family: 'Helvetica', sans-serif;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 0.25rem;
}

#menu .product-price {
    font-size: 1.5em;
    margin: 0;
    padding: 0;
    font-family: 'Helvetica', sans-serif;
    font-weight: 400;
}

.newline {
    border-top: 2px solid rgba(249, 177, 248, 0.5);
}

.product-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.product-row > div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1100px;
}

.ClassicTea h3 {
    color: #F54F1F;
}

.ClassicTea p {
    color: #000;
}

.ClassicTea h3 {
    background-color: rgb(245 79 31 / 30%);
}

.Sugarcane h3 {
    color: #40A69C;
}

.Sugarcane p {
    color: #000;
}

.Sugarcane h3 {
    background-color: rgb(64 166 156 / 30%);
}

.Milktea h3 {
    color: #ce7300;
}

.Milktea p {
    color: #000;
}

.Milktea h3 {
    background-color: rgb(206 115 0 / 30%);
}

.Ice-cream p {
    color: #000;
}

.Ice-cream h3 {
    background-color: rgba(140, 65, 78, 0.3);
}


.product-wrapper {
    margin-bottom: 3rem;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
}

.product-detail-wrapper {
    box-shadow: 0px 0px 20px rgb(0 0 0 / 30%);
    transition: all 150ms ease-in;
    padding-bottom: 20px;
    border-radius: 15px;
    overflow: hidden;
}

.product-detail-wrapper:hover {
    cursor: pointer;
    box-shadow: 0px 0px 25px rgb(0 0 0 / 50%);
}

.product-img-wrapper {
    height: 200px;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-img {
    width: 100%;
    min-height: 200px;
}

#menu .product-name-wrapper,
#menu .product-price-wrapper {
    padding-top: 2rem;
}

@media (max-width: 767px) {
    #title {
        margin-bottom: 2rem;
        font-size: 2rem;
    }
    .menu-category-box img {
        width: 40px;
    }
    .menu-category-box p {
        font-size: 0.875rem;
    }
    #menu h3 {
        font-size: 2.25rem;
    }
    .product-wrapper {
        margin-bottom: 2.5rem;
    }
    .product-img-wrapper {
        box-shadow: none;
        height: 150px;
        display: flex;
    }
    #menu .product-name {
        font-size: 1.5rem;
    }
    #menu .product-price {
        font-size: 1.25rem;
    }
    #menu .product-item-wrapper {
        padding: 10px 10px 0 10px;
    }
    .product-img-wrapper {
        min-width: unset !important;
        max-width: unset !important;
    }
    #menu-category-container {
        overflow: scroll;
        justify-content: flex-start !important;
    }
    #menu-category-wrapper {
        justify-content: flex-start !important;        
    }
    #menu-category-container .menu-category-box {
        margin-right: 15px;
        min-width: 100px;
    }
    .product-img {
        min-height: unset !important;
    }
}