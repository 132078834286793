/* Modal */
.modal-footer {
    display: none;
}
.modal-top-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}
.modal-top-nav-left .modal-back-btn{
    font-size: 1.5rem;
}
.modal-top-nav-left, .modal-top-nav-right {
    display: flex;
    align-items: center;
}
.modal-top-nav-right #shopping-cart-img{
    width: 25px;
}
#item-modal .modal-footer {
    text-align: left;
}
.item-modal-container {
    margin-bottom: 2rem;
}
.item-modal-container h5 {
    font-size: 1.5rem;
}
.quantity-btn {
    margin: 0 15px;
}
#decrease-btn {
    margin-left: 0;
}
.item-modal-container input[type="checkbox"]:checked + span {
    color: #E55812;
}
.item-modal-container input[type="radio"]:checked + span {
    color: #E55812;
}
.item-modal-container p {
    margin-top: 0;
    margin-bottom: 1rem;
}
#drink-taste p {
    margin-bottom: 0.5rem;
}
#add-btn {
    background-color: #222 !important;
    border-radius: 10px;
}
#cart-wrapper {
    display: none;
    color: #ff9a5f;
}
#cart-count {
    font-weight: 600;
}

.modal-top-nav {
    display: none;
}

.item-detail-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.modal #item-name {
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 5px;
}
.modal #item-price {
    margin: 0;
}
.modal #quantity {
    width: 25px;
    display: inline-flex;
    justify-content: center;
    font-weight: 600;
}
.modal-content [type="radio"]:checked+span:after {
    background-color: #ff9763;
    border: 2px solid #ff9763;
}
.modal #item-img-wrapper {
    width: 100%;
    height: 200px;
    overflow: hidden;
    margin: 10px;
    position: relative;
    border-radius: 10px;
    align-items: center;
}
.modal #item-img {
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
}
.modal .item-modal-container h5 {
    font-size: 1.25rem;
    font-weight: 600;
}
#shopping-cart-wrapper {
    position: relative;
}
#modal-cart-count {
    background-color: #fc975e;
    color: #fff;
    width: 20px;
    height: 20px;
    position: relative;
    display: inline-block;
    font-size: 0.7rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    border-radius: 50%;
    font-weight: 600;
    top: -10px;
    position: absolute;
    right: -10px;
}
.modal .modal-footer {
    height: 90px;
}

@media (max-width: 767px) {
    .modal-top-nav {
        display: flex;
    }
    .modal-top-nav {
        left: 0;
        position: fixed;
        padding: 10px 30px;
        top: 5px;
        width: 100%;
    }
    .modal .modal-content {
        padding: 10px;
        overflow-y: scroll;
        display: flex;
        height: 84vh;
        top: 8vh;
        position: relative;
        flex-direction: column;
    }
    .item-detail-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .modal #item-name {
        font-size: 1.75rem;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .modal #item-price {
        margin: 0;
    }
    .modal #item-img-wrapper {
        width: 100%;
        height: 200px;
        overflow: hidden;
        margin: 10px;
        position: relative;
        border-radius: 10px;
        align-items: center;
    }
    .modal #item-img {
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        position: relative;
    }
    .modal .item-modal-container h5 {
        font-size: 1.25rem;
        font-weight: 600;
    }
    #menu .product-item-wrapper {
        padding: 10px 10px 0 10px;
    }

    #shopping-cart-wrapper {
        position: relative;
    }
    #modal-cart-count {
        background-color: #fc975e;
        color: #fff;
        width: 20px;
        height: 20px;
        position: relative;
        display: inline-block;
        font-size: 0.7rem;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: inline-flex;
        border-radius: 50%;
        font-weight: 600;
        top: -10px;
        position: absolute;
        right: -10px;
    }
}

@media only screen and (max-width: 992px) {
    .modal {
        width: 100%;
        height: 100%;
        max-height: unset;
        top: 0 !important;
    }
}