.gray-bg {
    background-color: #fcfcfc;
}

#signup-section {
    padding: 3rem;
}

#signup-text {
    font-size: 1.5rem;
}

#signup-container {
    width: 80%;
}

#signup-container p {
    font-size: 1.5rem;
    margin-bottom: 0;
}

#signup-btn,
#signup-header-btn {
    background-color: #E55812;
    height: 42px;
    padding: 0.2rem 2rem;
    box-sizing: border-box;
}

#signup-error {
    color: red;
    font-size: 0.875rem !important;
    margin-top: 0;
}

@media (max-width: 767px) {
    #signup-container {
        width: 100%;
    }
}