#panel h1 {
  margin-bottom: 1rem;
}

#balance-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3rem;
}
#balance-container h5 {
  margin-right: 2rem;
}

#option-container {
  margin-bottom: 2rem;
}

#option-container .btn {
  margin-right: 20px;
}
.btn-container .btn {
  margin-right: 10px;
}

.status {
  font-weight: 600;
  text-transform: uppercase;
}

.status-not-send {
  color: #466ae9;
}
.status-not-activated {
  color: #7f5297;
}
.status-active {
  color: #51bd94;
}

/* Modal */
.modal h3 {
  margin-bottom: 3rem;
}
.modal-dropdown {
  margin-bottom: 2rem;
}
#create-gift-card-modal h3 {
  margin-bottom: 0;
}
#create-gift-card-modal p {
  margin: 0;
}
#create-gift-card-modal .modal-label {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
#create-gift-card-modal .modal-element p {
  margin-bottom: 1rem;
}


/* Other elements */
.recharge-toast {
  display: none;
}