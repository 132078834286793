.centered {
  display: flex;
  align-items: center;
  flex-direction: column;
}

#logo-img {
  width: 200px;
  padding-top: 100px;
}

#club-header {
  margin-bottom: 50px;
}