/* Common */
.centered {
	text-align: center;
}
.btn {
    border-radius: 5px;
}
.orange-btn, .orange-btn:focus {
    background-color: #E55812;
}
.orange-btn:hover {
    background-color: #ff9763 !important;
}
.spinner-orange, .spinner-orange-only {
	border-color: #E55812;
}
h1,h2,h3 {
	font-family: 'Helvetica', sans-serif;
	font-weight: 700;
}
h4,h5,p {
	font-family: 'Helvetica', sans-serif;
	font-weight: 400;
}
p {
	font-size: 1rem;
}
.error {
    color: red;
}
.form-control {
    display: block;
    box-sizing: border-box !important;
    width: 100%;
    height: 42px !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff !important;
    background-clip: padding-box;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.row:last-child {
	margin-bottom: 0;
}

.pristine-error {
	color: #f31a1a;
	text-align: left;
}

input[required]::after {
	content: '&#42';
}

@media (min-width: 992px) {
	p {
		font-size: 1.25rem;
	}
}
/* END - Common */